/******************************************************************************************
                                    IMPORTS
******************************************************************************************/
import { useState, useEffect } from "react";
import { useAssistants } from "../../../hooks/useAssistants";
// import MaintenanceAnnouncement from "../../Announcement/MaintenanceAnnouncement";

/******************************************************************************************
                                    COMPONENTS
******************************************************************************************/
import Square from "./Square";
import Square2 from "./Square2";
import { tourSteps } from "../../../config/tourSteps";
import ModalButton from "./ModalButton";
import PrimaryModal from "./PrimaryModal"; 

/******************************************************************************************
                                    TYPES
******************************************************************************************/
import { IAssistant } from "../../../types/interfaces";

/******************************************************************************************
                                    HOOKS
******************************************************************************************/
import { useUser } from "@clerk/clerk-react";
import Joyride, { CallBackProps, STATUS } from "react-joyride";

function DashboardContent() {
  const { assistants } = useAssistants();
  const { user } = useUser();
  const [run, setRun] = useState(false);
  const [isPrimaryModalOpen, setIsPrimaryModalOpen] = useState(false); // State for PrimaryModal
  // const [showAnnouncement, setShowAnnouncement] = useState(false); // State for showing announcement

  const TOUR_VERSION = "1.0"; // Increment this when you want to show tour again
  const storageKey = `dashboardTourShown_v${TOUR_VERSION}`;

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, type } = data;
    if (
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED ||
      (action === "close" && type === "step:after")
    ) {
      localStorage.setItem(storageKey, "true");
      setRun(false);
    }
  };

  /******************************************************************************************
                                    TOUR HANDLING
******************************************************************************************/
  useEffect(() => {
    const tourShown = localStorage.getItem(storageKey);
    if (tourShown !== "true") {
      const timer = setTimeout(() => {
        setRun(true);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setRun(false);
    }
  }, [storageKey]);

  /******************************************************************************************
                                    PRIMARY MODAL HANDLING
******************************************************************************************/
  const handleOpenPrimaryModal = () => setIsPrimaryModalOpen(true);
  const handleClosePrimaryModal = () => setIsPrimaryModalOpen(false);

/******************************************************************************************
                                    COMPONENT START
******************************************************************************************/

  // useEffect(() => {
  //   // Check if announcement should be shown
  //   const checkAnnouncementVisibility = () => {
  //     const dismissedUntil = localStorage.getItem('maintenanceAlertDismissed');
  //     if (!dismissedUntil) {
  //       setShowAnnouncement(true);
  //       return;
  //     }

  //     const dismissedUntilDate = new Date(dismissedUntil);
  //     const now = new Date();

  //     if (now > dismissedUntilDate) {
  //       setShowAnnouncement(true);
  //       localStorage.removeItem('maintenanceAlertDismissed');
  //     } else {
  //       setShowAnnouncement(false);
  //     }
  //   };

  //   checkAnnouncementVisibility();
  //   // Check visibility every minute
  //   const interval = setInterval(checkAnnouncementVisibility, 60000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="w-full h-contain bg-brand-green">
      <Joyride
        steps={tourSteps}
        run={run}
        callback={handleJoyrideCallback}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: "#FFE7C3",
            backgroundColor: "#FFE7C3",
            primaryColor: "#004B27",
            textColor: "#004B27",
          },
        }}
      />

      <div className="h-contain items-center w-[100%]">
        <div className="px-8 pb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-6">
            <div className="col-span-2 md:col-span-4 lg:col-span-4 pt-16 lg:pt-24">
              <h5 className="font-Black text-brand-orange-cream text-brand-cream text-[24px] md:text-5xl">
                Hi {user?.firstName},
              </h5>
              <h5 className="font-Black text-brand-cream text-[21px] md:text-5xl">
                How can we help you today?
              </h5>
            </div>

            {assistants &&
              assistants.map((assistant: IAssistant, index: number) => (
                <div
                  key={index}
                  className="w-[150px] h-[120px] md:w-[150px] lg:w-[200px] md:h-[150px] lg:h-[150px] relative"
                >
                  <Square
                    title={assistant.jobTitle}
                    description={assistant.description}
                    _id={assistant._id}
                  />
                </div>
              ))}

            <div className="w-[150px] h-[120px] md:w-[150px] lg:w-[200px] md:h-[150px] lg:h-[150px] relative dash-step-4">
              <Square2
                title="Resources"
                description="Need some help with implementing Ai? Jump into our resources for guidance with specific tasks."
                link="/resources"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-6 right-6 z-50 dash-step-5">
        <ModalButton onClick={handleOpenPrimaryModal} />
      </div>

      {/* PrimaryModal component handling */}
      {isPrimaryModalOpen && <PrimaryModal onClose={handleClosePrimaryModal} />}
    </div>
  );
}

export default DashboardContent;
