import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "./ThemeContext";
import ChatWindow from "./ChatWindow";
import Sidebar from "./Sidebar";
import ThemeSwitch from "./ThemeSwitch";
import { service } from "../../../services";
import { useAuth } from "@clerk/clerk-react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RelatedAssistantsPopup from "./RelatedAssistantsPopup";
import SwitchAccessShortcutAddSharpIcon from "@mui/icons-material/SwitchAccessShortcutAddSharp";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { tourStepsChat } from "../../../config/tourStepsChat"; // Ensure this is the correct path
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAssistant } from "../../../context/assistantContext";

const Chat = () => {
  const { assistantId } = useParams();
  const { theme } = useTheme();
  const { getToken } = useAuth();
  const { setSelectedAssistant } = useAssistant(); // Import setSelectedAssistant from AssistantContext

  const [assistantData, setAssistantData] = useState<any>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
  const [run, setRun] = useState(false); // State for running the Joyride tour

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, type } = data;
    if (
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED ||
      (action === "close" && type === "step:after")
    ) {
      localStorage.setItem("chatTourShown", "true");
      setRun(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (assistantId) {
        setSelectedAssistant(assistantId); // Set the selected assistant
        const assistant = await service.assistantService.getAssistant(
          await getToken(),
          assistantId
        );

        console.log(assistant);
        setAssistantData(assistant); // Store the assistant's data in state
      }
    };

    getData();

    const tourShown = localStorage.getItem("chatTourShown");
    if (!tourShown) {
      setRun(true);
    }
  }, [assistantId, getToken, setSelectedAssistant]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`${
          theme === "dark" ? "bg-brand-green" : "bg-brand-cream"
        } w-screen h-screen flex`}
      >
        <Joyride
          steps={tourStepsChat}
          run={run}
          callback={handleJoyrideCallback}
          continuous
          showProgress
          showSkipButton
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: "#FFE7C3", // Orange arrow color
              backgroundColor: "#FFE7C3",
              primaryColor: "#004B27",
              textColor: "#004B27",
            },
          }}
        />
        {isSidebarOpen && (
          <div
            className={`${
              theme === "dark" ? "bg-brand-green-light" : "bg-brand-green-light"
            } h-screen lg:w-1/5 sidebar-transition`}
          >
            <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
          </div>
        )}
        <IconButton
          onClick={toggleSidebar}
          className={`absolute top-1/2 transform -translate-y-1/2  ${
            isSidebarOpen ? "left-[0%]" : "left-0"
          }`}
          sx={{ color: theme === "dark" ? "white" : "black" }}
        >
          {isSidebarOpen ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
        </IconButton>
        <div
          className={`h-screen ${
            isSidebarOpen ? "w-4/5" : "w-full"
          } transition-width duration-1800 ease-in-out`}
        >
          {assistantId && assistantData && (
            <h2 className="text-brand-green-light text-center text-2xl md:text-6xl lg:text-7xl xl:text-[92px] 2xl:text-[102px] pt-2 lg:pt-2 mb-16 opacity-60">
              {assistantData.jobTitle}
            </h2>
          )}

          {/* Workflow Div */}
          <div className={`flex-1 transition-all duration-1800 ease-in-out`}>
            <div className="w-8 h-8 absolute bottom-0 mb-12">
              <div className="md:flex w-full justify-center items-center hidden">
                <button
                  onClick={togglePopup}
                  className="w-full h-full rounded-[100px] transform hover:scale-[120%] ease-in-out transition-transform duration-300 bg-brand-green-light flex justify-center items-center text-center px-2 py-1 mr-6 step-5 absolute z-[9999]"
                >
                  <SwitchAccessShortcutAddSharpIcon className="w-8 h-8 text-brand-green-dark" />
                </button>
              </div>
            </div>
            <ChatWindow />
          </div>

          <div className="absolute bottom-1 right-1 text-gray-500 md:flex items-center xl:pl-32 hidden">
            <div className="flex step-4">
              <ThemeSwitch />
            </div>
          </div>

          {assistantId && assistantData && (
            <RelatedAssistantsPopup
              relatedAssistants={assistantData.relatedAssistants}
              onClose={togglePopup} // Pass the togglePopup function
              isOpen={isPopupOpen} // Pass the isOpen state
            />
          )}
        </div>
      </div>
    </DndProvider>
  );
};

export default Chat;
