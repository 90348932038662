/* eslint-disable react-hooks/exhaustive-deps */
import { useUser } from "@clerk/clerk-react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import React, { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import "../../../App.css";
import { useChat } from "../../../context/ChatContext";
import Icon from "../../Icons/Icon";
import Markdown from "./Markdown";
import TextInput from "./TextInput";
import { useTheme } from "./ThemeContext";
import { useSearchParams } from "react-router-dom";
import { useAssistant } from "../../../context/assistantContext";


interface IMessage {
	_id: string;
	question: string;
	answer?: string;
	component?: React.ReactNode;
}

const ChatWindow = () => {
	const {
		selectedChat,
		setSelectedChat,
		createChat,
		question,
		sendChat,
		selectChat,
		isMessageLoading,
	} = useChat();
	const { prompts } = useAssistant();
	const { user } = useUser();
	const { theme } = useTheme();
	const [searchParams] = useSearchParams();
	const [response, setResponse] = useState<string>("");
	const [isUserScrolling, setIsUserScrolling] = useState<boolean>(false);
	const [showPrompts, setShowPrompts] = useState<boolean>(true);
	const hiddenTextAreaRef = useRef<HTMLTextAreaElement>(null);
	const socketRef = useRef<Socket | null>(null);
	const messagesEndRef = useRef<HTMLDivElement | null>(null);
	const chatContainerRef = useRef<HTMLDivElement | null>(null);
	const [waitingMessage, setWaitingMessage] = useState<string>("");

	const handleOpenAiResponse = (fullResponse: string) => {
		console.log('Streaming response:', fullResponse);
		const cleanedResponse = fullResponse.replace(/```markdown|```/g, "").trim();
		
		setSelectedChat((currentChat: any) => {
			if (!currentChat || currentChat._id !== selectedChat?._id) return currentChat;
			const newMessages = [...currentChat.messages];
			newMessages[newMessages.length - 1] = {
				_id: Date.now().toString(),
				question: question,
				answer: cleanedResponse || "",
			};
			return {
				...currentChat,
				messages: newMessages,
			};
		});
	};

	const handleStreamEnd = (fullResponse: string) => {
		const cleanedResponse = fullResponse.replace(/```markdown|```/g, "").trim();
		setResponse(cleanedResponse);
		
		setSelectedChat((currentChat: any) => {
			if (!currentChat || currentChat._id !== selectedChat?._id) return currentChat;
			const newMessages = [...currentChat.messages];
			newMessages[newMessages.length - 1] = {
				_id: Date.now().toString(),
				question: question,
				answer: cleanedResponse,
			};
			return {
				...currentChat,
				messages: newMessages,
			};
		});
	};

	useEffect(() => {
		if (selectedChat) {
			if (socketRef.current) {
				socketRef.current.disconnect();
				socketRef.current = null;
			}

			socketRef.current = io(process.env.REACT_APP_API_BASE_URL ?? "");
			socketRef.current.on("openai_response", handleOpenAiResponse);
			socketRef.current.on("stream_end", handleStreamEnd);
			socketRef.current.emit("joinRoom", selectedChat._id);

			return () => {
				if (socketRef.current) {
					socketRef.current.off("openai_response", handleOpenAiResponse);
					socketRef.current.off("stream_end", handleStreamEnd);
					socketRef.current.disconnect();
					socketRef.current = null;
				}
			};
		}
	}, [selectedChat, question]);

	useEffect(() => {
		const chatId = searchParams.get("chat");
		if (chatId) {
			selectChat(chatId);
		}
	}, [searchParams]);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		if (isMessageLoading) {
			setWaitingMessage("Let me work on that ...");

			timeoutId = setTimeout(() => {
				setWaitingMessage("Still thinking ...");
			}, 5000);
		} else {
			setWaitingMessage("");
		}

		return () => clearTimeout(timeoutId);
	}, [isMessageLoading]);

	const handlePromptClick = (prompt: string) => {
		sendChat(prompt, selectedChat?._id ?? "defaultRoom");
		setShowPrompts(false);
	};

	const removeMarkdown = (text: string) => {
		return text
			.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/g, "$1")
			.replace(/[*_`~>]/g, ""); // Remove other markdown symbols
	};

	const handleCopyClick = (text: string) => {
		const plainText = removeMarkdown(text);
		if (hiddenTextAreaRef.current) {
			hiddenTextAreaRef.current.value = plainText;
			hiddenTextAreaRef.current.select();
			document.execCommand("copy");
		}
	};

	const handleUserScroll = () => {
		if (!chatContainerRef.current) return;
		const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
		setIsUserScrolling(scrollHeight - scrollTop > clientHeight + 50);
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
			setResponse("");
		}
	};

	const questionClass = `mb-2 text-[16px] mb-6 ${
		theme === "dark" ? "text-brand-green-dark" : "text-brand-green-dark"
	}`;

	useEffect(() => {
		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, []);

	useEffect(() => {
		if (!isUserScrolling && messagesEndRef.current) {
			const scrollOptions: ScrollIntoViewOptions = {
				behavior: isMessageLoading ? 'auto' : 'smooth',
				block: 'end' as ScrollLogicalPosition
			};
			messagesEndRef.current.scrollIntoView(scrollOptions);
		}
	}, [selectedChat?.messages, isUserScrolling, isMessageLoading]);

	useEffect(() => {
		if (selectedChat) {
			setShowPrompts(false);
		}
	}, [selectedChat]);

	return (
		<div
			className="flex flex-col w-full max-w-full overflow-hidden items-center"
			style={{
				height: "calc(100vh - 200px)",
				minHeight: "300px",
				position: "relative"
			}}
		>
			<div
				className="flex-1 overflow-auto hide-scrollbar w-full"
				ref={chatContainerRef}
				onScroll={handleUserScroll}
			>
				{selectedChat?.messages.map((message: IMessage, index: number) => (
					<div className="w-full max-w-[800px] mx-auto my-8" key={message._id}>
						<div className="flex items-start">
							<img
								src={user?.imageUrl}
								alt="profile"
								className="mr-2"
								style={{ width: "24px", height: "24px", borderRadius: "50%" }}
							/>
							<div className="flex flex-col mb-4">
								<p
									className={`${
										theme === "dark"
											? "text-brand-logo"
											: "text-brand-green-dark"
									} text-[16px] font-bold`}
								>
									You
								</p>
								<p className={questionClass}>
									{message?.question?.split("\n")?.map((question, idx) => (
										<span key={idx}>
											{question}
											<br />
										</span>
									))}
								</p>
							</div>
						</div>
						{message.component ? (
							<Markdown data={response} />
						) : (
							<div className="flex items-start">
								<Icon name="ai" className="mr-2 -mt-4" />
								<div className="flex flex-col">
									<p
										className={`${
											theme === "dark" ? "text-brand-logo" : "text-brand-green"
										} text-[16px] font-bold mb-2`}
									>
										sloane
									</p>
									{isMessageLoading && index === selectedChat.messages.length - 1 && !message.answer ? (
										<div className="text-brand-green-dark">
											{waitingMessage}
											<span className="animate-ellipsis">...</span>
										</div>
									) : (
										<>
											<Markdown data={message.answer ?? ""} />
											<div className="w-full justify-center items-center ml-[40.5%]">
												<button
													className="text-brand-green-dark text-sm underline mt-2 transform hover:scale-[115%] ease-in-out duration-500 text-center"
													onClick={() => handleCopyClick(message.answer ?? "")}
												>
													Copy Response
												</button>
											</div>
										</>
									)}
								</div>
							</div>
						)}
						{index === selectedChat?.messages.length - 1 && (
							<div ref={messagesEndRef} />
						)}
					</div>
				))}
			</div>

			{/* Prompts */}
			{showPrompts && (
				<div className="flex flex-wrap justify-center py-4 w-[70%] step-2">
					{prompts.map((prompt) => (
						<button
							key={Math.random()}
							className="mx-2 p-2 mb-2 bg-brand-cream text-brand-green text-[12px] rounded-[100px] hover:text-brand-green-dark shadow-sm transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer border-[1px] border-brand-green"
							onClick={() => handlePromptClick(prompt.prompt)}
						>
							{prompt.display}
						</button>
					))}
				</div>
			)}

			<button
				className=" mb-3 text-brand-green-dark text-[12px] rounded-[100px] transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer step-3"
				onClick={() => setShowPrompts(!showPrompts)}
			>
				{showPrompts ? "Hide Prompts" : "Show Prompts"}
			</button>

			{selectedChat ? (
				<div className="w-[95%] h-contain justify-center items-center xl:px-32 mb-2">
					<TextInput room={selectedChat._id ?? "defaultRoom"} />
				</div>
			) : (
				<div
					onClick={() => {
						createChat();
						setShowPrompts(true);
					}}
					className="absolute top-[30%] border-brand-cream hidden md:block px-4 py-2 rounded-[50px] border-[1px] cursor-pointer"
				>
					<p
						className={`text-2xl font-thin flex justify-center items-center mb-0 step-1 ${
							theme === "dark" ? "text-brand-cream" : "text-brand-blue"
						}`}
					>
						<ArrowBackRoundedIcon className="w-10 h-8 " />
						Click New Chat To Begin
					</p>
				</div>
			)}
			<textarea
				ref={hiddenTextAreaRef}
				style={{ position: "absolute", left: "-9999px", top: "0" }}
			/>
		</div>
	);
};

export default ChatWindow;
